"use client";

import { useLanguage, useTranslation } from "@/Utils/lanHook";
import Image from "next/image";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FiMenu, FiX } from "react-icons/fi";

const navLinks = {
  "nav.landingLinks.home": "/home",
  "nav.landingLinks.about": "/about",
  "nav.landingLinks.contact": "/contact",
  "nav.landingLinks.blog": "/blog",
  "nav.landingLinks.shop": "/shop",
};

export default function LandingNavBar() {
  const [activeLink, setActiveLink] = useState("nav.landingLinks.home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  const { language, setReduxLanguage } = useLanguage();

  const handleLangToggle = () => {
    const newLanguage = language === "ar" ? "en" : "ar";
    setReduxLanguage(newLanguage);
    console.log(`Current language: ${language}`);
    document.location.replace("/");
  };

  return (
    <>
      {/* Desktop Navbar */}
      <nav className="hidden lg:flex justify-between items-center pt-5 px-4">
        <div className="w-1/2 flex justify-between items-center">
          <span className="w-2/12">
            <Image
              className="object-contain"
              src="Logo.png"
              alt="logo"
              width={1920}
              height={1080}
            />
          </span>
          <ul className="w-3/4 flex justify-between items-center text-nowrap">
            {Object.keys(navLinks).map((link) => (
              <li
                key={link}
                className={`cursor-pointer py-2 px-6 ${
                  activeLink === link
                    ? "bg-[#f9f9f9] text-primary rounded-md"
                    : ""
                }`}
                onClick={() => setActiveLink(link)}
              >
                {t(link)}
              </li>
            ))}
          </ul>
        </div>
        <div className="w-2/12 flex justify-between items-center gap-4">
          <div
            onClick={handleLangToggle}
            className="flex-shrink-0 cursor-pointer w-[50px] h-[50px] p-2 bg-[#f9f9f9] rounded-full flex items-center justify-center"
          >
            <div className="text-black text-lg font-normal">
              {language.toUpperCase()}
            </div>
          </div>
          <span className="bg-[#f9f9f9] text-primary px-12 py-2 rounded-md text-nowrap">
            {t("nav.landingLinks.join")}
          </span>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <div className="lg:hidden flex justify-between items-center p-4">
        <Image
          className="object-contain w-[20%]"
          src="Logo.png"
          alt="logo"
          width={1920}
          height={1080}
        />
        <div className="flex gap-4 items-center">
          <div className="flex justify-between items-center gap-4">
            <div
              onClick={handleLangToggle}
              className="flex-shrink-0 cursor-pointer w-[50px] h-[50px] p-2 bg-[#f9f9f9] rounded-full flex items-center justify-center"
            >
              <div className="text-black text-lg font-normal">
                {language.toUpperCase()}
              </div>
            </div>
            <span className="bg-[#f9f9f9] text-primary px-12 py-2 rounded-md text-nowrap">
              {t("nav.landingLinks.join")}
            </span>
          </div>
          <span
            onClick={() => setIsMenuOpen(true)}
            className="text-3xl text-primary cursor-pointer bg-white/90 p-2 rounded-full"
          >
            <FiMenu className="text-2xl cursor-pointer " />
          </span>
        </div>
      </div>

      {/* Overlay Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ type: "spring", stiffness: 100 }}
            className="lg:hidden fixed top-0 right-0 w-full h-full bg-primary/95 z-50 flex flex-col items-center justify-center text-white"
          >
            <span
              onClick={() => setIsMenuOpen(false)}
              className="text-3xl absolute top-6 text-primary right-6 cursor-pointer bg-white/90 p-2 rounded-full"
            >
              <FiX />
            </span>
            <ul className="space-y-8 text-lg">
              {Object.keys(navLinks).map((link) => (
                <li
                  key={link}
                  className="cursor-pointer"
                  onClick={() => {
                    setActiveLink(link);
                    setIsMenuOpen(false);
                  }}
                >
                  {t(link)}
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
