import { useRef } from "react";
import { motion } from "framer-motion";
import { LandingKeyIcon } from "@/svgs/landingSvg";
import { useTranslation } from "@/Utils/lanHook";
import { ArrowBackIosNewRounded } from "@mui/icons-material";

const featchers = {
  featcher1: {
    title: "landingContent.keyHead1",
    subtitle: "landingContent.keysub1",
  },
  featcher2: {
    title: "landingContent.keyHead1",
    subtitle: "landingContent.keysub1",
  },
  featcher3: {
    title: "landingContent.keyHead1",
    subtitle: "landingContent.keysub1",
  },
  featcher4: {
    title: "landingContent.keyHead1",
    subtitle: "landingContent.keysub1",
  },
  featcher5: {
    title: "landingContent.keyHead1",
    subtitle: "landingContent.keysub1",
  },
  featcher6: {
    title: "landingContent.keyHead1",
    subtitle: "landingContent.keysub1",
  },
  featcher7: {
    title: "landingContent.keyHead1",
    subtitle: "landingContent.keysub1",
  },
  featcher8: {
    title: "landingContent.keyHead1",
    subtitle: "landingContent.keysub1",
  },
  featcher9: {
    title: "landingContent.keyHead1",
    subtitle: "landingContent.keysub1",
  },
  featcher10: {
    title: "landingContent.keyHead1",
    subtitle: "landingContent.keysub1",
  },
};

export default function KeyFeatures() {
  const { t } = useTranslation();
  const containerRef = useRef(null);

  const scroll = (scrollOffset) => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: scrollOffset,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="relative w-full">
      <motion.div
        ref={containerRef}
        className="flex w-full overflow-x-scroll scroll-smooth space-x-4"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        {Object.keys(featchers).map((featcher) => (
          <div
            key={featcher}
            className="w-full md:w-1/2 lg:w-1/3 flex-shrink-0 flex flex-col items-center space-y-4"
          >
            <LandingKeyIcon />
            <h1 className="font-bold text-xl text-center">
              {t(featchers[featcher].title)}
            </h1>
            <p className="text-center">{t(featchers[featcher].subtitle)}</p>
          </div>
        ))}
      </motion.div>

      <div className="absolute bottom-0 w-full flex justify-between translate-y-3/4">
        <button
          onClick={() => scroll(-300)}
          className="bg-primary/30 text-primary p-4 rounded-full"
        >
          <ArrowBackIosNewRounded />
        </button>
        <button
          onClick={() => scroll(300)}
          className="bg-primary/30 text-primary p-4 rounded-full"
        >
          <ArrowBackIosNewRounded className="rotate-180" />
        </button>
      </div>
    </div>
  );
}
