"use client";

import KeyFeatchers from "@/components/keyFeatchers";
import LandingNavBar from "@/components/landingNavBar";
import { LandingSvg } from "@/svgs/landingSvg";
import { hexToRgb, primaryColor } from "@/Utils/appColors";
import { useLanguage, useTranslation } from "@/Utils/lanHook";
import { useEffect } from "react";
export default function LandingPage() {
  const { language } = useLanguage();
  useEffect(() => {
    if (typeof window !== "undefined") {
    }
    if (typeof window !== "undefined") {
      localStorage.removeItem("cart");
      localStorage.removeItem("accountSettings");
      localStorage.removeItem("branch");
      document.documentElement.style.setProperty(
        "--primary-color",
        hexToRgb(primaryColor)
      );
    }
  });
  const { t } = useTranslation();
  return (
    <main className="items-center">
      <section
        id="section1"
        className="bg-gradient-to-b from-primary/40 to-white"
      >
        <LandingNavBar />
        <div className="flex flex-col lg:flex-row justify-center items-center mt-10 px-5 w-[94%] lg:w-[97%]">
          <span>
            <h1 className="font-bold text-3xl text-center">
              {t("landingContent.headline")}
            </h1>
            <p className="px-[7%] mt-5 text-gray-500">
              {t("landingContent.headsubline")}
            </p>
            <button
              className="bg-primary text-white p-2 rounded-md mt-10 mb-5 lg:mb-0 mx-auto block"
              type="button"
            >
              {t("landingContent.demobutton")}
            </button>
          </span>
          <span className="relative">
            <img
              src="lapFrame.png"
              alt="landing"
              width={1920}
              height={1080}
              className="object-contain"
            />
            <img
              src="phoneFrame.png"
              alt="landing"
              className={`object-contain absolute ${
                language === "ar"
                  ? "left-0 -translate-x-[40%]"
                  : "right-0 translate-x-[40%]"
              } top-1/2 -translate-y-1/2 h-1/2`}
            />
          </span>
        </div>
      </section>
      <section id="section2" className="mt-10 px-5">
        <div className="justify-self-center items-center">
          <h1 className="font-bold text-3xl mb-6 text-center">
            {t("landingContent.customerTrust")}
          </h1>
          <LandingSvg />
        </div>
        <div className="lg:flex mb-5 lg:mb-0 justify-center items-center mt-10">
          <span className="w-1/2">
            <h1 className="font-bold text-3xl mb-4">
              {t("landingContent.smartoffes")}
            </h1>
            <p className="mt-5 text-gray-500">
              {t("landingContent.subsmartoffers")}
            </p>
          </span>
          <span className="w-1/2">
            <img src="landing2.png" alt="landing2" className="rounded-lg" />
          </span>
        </div>
      </section>
      <section id="section3" className="mt-10 px-5">
        <h1 className="font-bold text-3xl mb-6 text-center bg-gradient-to-r from-transparent via-primary/10 to-transparent w-1/2 mx-auto">
          {t("landingContent.key")}
        </h1>
        <KeyFeatchers />
      </section>
    </main>
  );
}
